import React from 'react'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { useNotifications } from '@context/notifications'
import {
  Grid,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Typography,
  MenuItem,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material'
import usePasswordVisibility from '@hooks/usePasswordVisibility'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { states } from '@helpers/geo-location'
import { useFirebaseContext } from '@context/firebaseContext'
import { useModalsContext } from '@context/modalContext'
import {
  Subtitle,
  RequiredFieldsMessage,
  PhoneInput,
  FormGrid,
  TitleWrapper,
  InputGrid,
  ButtonGrid,
  SubmitButton,
} from '../Common'
import Loader from './Loader'
import { useProfilePage } from './hooks'
import { PageWrapper } from '../Motion'

const spacing = 2

const EmailVerification = styled('button')(({ theme, isVerified }) => ({
  position: 'absolute',
  right: '8px',
  top: '16px',
  color: isVerified ? theme.palette.success.main : theme.palette.primary.main,
  cursor: isVerified ? 'default' : 'pointer',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 500,
}))

const ProfilePage = () => {
  const theme = useTheme()
  const { user } = useFirebaseContext()
  const auth = getAuth()
  const { actions } = useModalsContext()
  const { showPassword, onVisibilityToggle, handleMouseDownPassword } = usePasswordVisibility()
  const { formik, isLoading, isSubmitting, isEmailChanged } = useProfilePage()
  const { firstName, lastName, email, zipcode } = formik.values
  const isDisabled = !(firstName && lastName && email && zipcode)
  const isVerified = user?.emailVerified

  const sendVerification = async e => {
    e.preventDefault()
    if (auth.currentUser.emailVerified) return
    await sendEmailVerification(auth.currentUser)
      .then(
        actions.genericModal({
          overrideState: {
            componentData: {
              title: 'Email Verificaiton',
            },
            styles: {
              '.MuiPaper-root': {
                maxWidth: '450px',
                maxHeight: '265px',
                overflow: 'hidden',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
              },
            },
          },
          data: { PredefinedComponentModal: 'email_verification' },
        }),
      )
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.error(errorCode, errorMessage)
      })
  }

  return (
    <PageWrapper>
      <TitleWrapper>
        <Typography component="h2" variant="h4">
          Profile
        </Typography>
      </TitleWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <RequiredFieldsMessage />
          <FormGrid component="form" container spacing={spacing} onSubmit={formik.handleSubmit}>
            <Grid container spacing={spacing} item xs={12}>
              <Grid item xs>
                <Subtitle text="Contact Information" />
              </Grid>
            </Grid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="firstName"
                  autoComplete="given-name"
                  name="firstName"
                  label="First Name"
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="lastName"
                  autoComplete="family-name"
                  name="lastName"
                  label="Last Name"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </InputGrid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid item xs={12} sm={6} md={4}>
                <PhoneInput
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="phone"
                  autoComplete="tel"
                  name="phone"
                  label="Phone Number"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {/* apply here */}
                <div style={{ position: 'relative' }}>
                  {' '}
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="email"
                    autoComplete="email"
                    name="email"
                    label="Email Address"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <EmailVerification type="button" onClick={sendVerification} isVerified={isVerified}>
                    {isVerified ? 'Verified' : 'Verify'}
                  </EmailVerification>
                </div>
              </Grid>
            </InputGrid>
            <Grid container spacing={spacing} item xs={12}>
              <Grid item xs>
                <Subtitle text="Delivery Address" />
              </Grid>
            </Grid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="address1"
                  autoComplete="address-line1"
                  name="address1"
                  label="Address Line 1"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                  error={formik.touched.address1 && Boolean(formik.errors.address1)}
                  helperText={formik.touched.address1 && formik.errors.address1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="address2"
                  autoComplete="address-line2"
                  name="address2"
                  label="Address Line 2"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  error={formik.touched.address2 && Boolean(formik.errors.address2)}
                  helperText={formik.touched.address2 && formik.errors.address2}
                />
              </Grid>
            </InputGrid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="city"
                  autoComplete="address-level2"
                  name="city"
                  label="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{
                    MenuProps: {
                      sx: {
                        maxHeight: '400px',
                      },
                    },
                  }}
                  id="state"
                  autoComplete="address-level1"
                  name="state"
                  label="State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  {states.map(([label, value]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </InputGrid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="zipcode"
                  autoComplete="postal-code"
                  name="zipcode"
                  label="ZIP Code"
                  required
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                />
              </Grid>
              <Grid aria-hidden="true" item xs={12} sm={6} md={4}>
                {isEmailChanged && (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="currentPassword"
                    autoComplete="current-password"
                    name="currentPassword"
                    label="Current Password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                    helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onVisibilityToggle('password')}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            </InputGrid>
            <InputGrid item container xs={12} spacing={spacing}>
              <Grid aria-hidden="true" item xs={12} sm={6} md={4} />
              <ButtonGrid item xs={12} sm={6} md={4}>
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <SubmitButton
                    disabled={isDisabled}
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    SAVE
                  </SubmitButton>
                )}
              </ButtonGrid>
            </InputGrid>
          </FormGrid>
        </>
      )}
    </PageWrapper>
  )
}

export default ProfilePage
