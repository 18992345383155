// icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'
import LockIcon from '@material-ui/icons/LockOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import FavoriteIcon from '@material-ui/icons/FavoriteBorderOutlined'
import PersonIcon from '@material-ui/icons/PersonOutline'
// components
import ProfilePage from '../ProfilePage'
import PasswordPage from '../PasswordPage'
import OrdersPage from '../OrdersPage'
import OrdersListPage from '../OrdersListPage'
import OrdersDetailsPage from '../OrdersDetailsPage'

export default [
  {
    id: `profile-1`,
    path: '/account/profile',
    component: ProfilePage,
    label: 'Profile',
    icon: AccountCircleIcon,
  },
  {
    id: 'order-3',
    path: '/account/order',
    component: OrdersPage,
    label: 'Orders',
    icon: LocalOfferIcon,
  },
  {
    id: 'order-4',
    path: '/account/order/list',
    component: OrdersListPage,
    label: 'Orders List',
    subroute: true,
  },
  {
    id: 'order-5',
    path: '/account/order/details',
    component: OrdersDetailsPage,
    label: 'Orders Details',
    subroute: true,
  },
  {
    id: 'security-2',
    path: '/account/password',
    label: 'Security',
    icon: LockIcon,
    component: PasswordPage,
  },
]
