import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { Link, Redirect } from '@gatsbyjs/reach-router'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import usePageAnalytics from '@hooks/usePageAnalytics'
import { useNotifications } from '@context/notifications'
import { useTheme } from '@mui/material'
import {
  makeStyles,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  useMediaQuery,
  alpha,
  Box,
  CircularProgress,
} from '@material-ui/core'
import links from './desktopLinks'
// content components
import { FramerRouter } from '../Motion'
import MobileAppBar from '../MobileAppBar'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& *': {
      outline: 'none !important',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    '& > span': {
      color: '#f2f2f2',
      display: 'block',
      padding: '16px 20px',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
  },
  paper: {
    position: 'sticky',
    top: '147px',
    zIndex: 1,
    height: '100%',
    justifyContent: 'space-between',
  },
  content: {
    overflowX: 'hidden',
    backgroundColor: '#fff',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 0,
    minHeight: '75vh',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
      paddingTop: '80px',
      margin: '0 -0.9375rem',
    },
  },
  link: {
    position: 'relative',
    zIndex: 1,
    '&[data-active="true"]:after': {
      content: "''",
      position: 'absolute',
      display: 'inline-block',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      width: '4px',
      zIndex: 2,
    },
    '&[data-active="true"] svg': {
      color: theme.palette.primary.main,
    },
  },
  bcWrapper: {
    [theme.breakpoints.down('md')]: {
      padding: `10px 0`,
    },
    '& nav ol li:nth-last-child(2)': {
      display: 'inline-block !important',
      height: '1.2rem',
      verticalAlign: 'middle',
    },
    '& nav ol li:last-child': {
      display: 'inline-block !important',
      height: '1.2rem',
      verticalAlign: 'middle',
    },
  },
}))

const onClick = path => e => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === path) {
      e.preventDefault()
    }
  }
}

const breadcrumbData = [{ label: 'My Account', level: 1, category: 'accounts' }]

const AccountsDrawer = () => {
  const { user, loading } = useFirebaseContext()
  const auth = getAuth()
  const { enqueue } = useNotifications()
  const showPageDefault = !_isEmpty(user) && !_isUndefined(user)
  const { actions } = useModalsContext()
  const [showPage, setShowPage] = useState(showPageDefault)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const isVerifiedUser = user?.emailVerified

  const isDisabled = label => {
    if (label === 'Profile') {
      return false
    }
    return !isVerifiedUser
  }

  usePageAnalytics({
    type: 'Accounts Dashboard',
    title: 'My Account',
  })

  const path = typeof window !== 'undefined' && window.location.pathname

  // Sends email verification email and displays NoTemplateEmailVerification modal
  const sendVerificationEmail = currentUserAuth => {
    sendEmailVerification(currentUserAuth)
      .then(
        actions.genericModal({
          overrideState: {
            componentData: {
              title: 'Email Verificaiton',
            },
            styles: {
              '.MuiPaper-root': {
                maxWidth: '450px',
                maxHeight: '265px',
                overflow: 'hidden',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
              },
            },
          },
          data: { PredefinedComponentModal: 'email_verification' },
        }),
      )
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.error(errorCode, errorMessage)
      })
  }

  useEffect(() => {
    if (!loading) {
      if (!_isEmpty(user) && !_isUndefined(user)) {
        setShowPage(true)
      } else {
        navigate('/')
      }
      // Prompts user to verify email via toast.
      if (!isVerifiedUser && user) {
        setTimeout(() => {
          enqueue(
            {
              variant: 'sectioned',
              body: 'Please verify your email for full access to your Rooms To Go account',
              severity: 'info',
              sectionedColors: {
                primary: '#0053a0',
                secondary: '#E7EFF6',
              },
              button: {
                text: 'VERIFY EMAIL',
                handleClick: () => sendVerificationEmail(auth.currentUser),
              },
            },
            500,
          )
        })
      }
    }
    return () => clearTimeout()
    // eslint-disable-next-line
  }, [user, loading])

  if (loading && !showPage) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '40vh',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="4rem" />
      </Box>
    )
  }

  return (
    <>
      <div className={classes.bcWrapper}>
        <Breadcrumbs productBreadcrumbs={breadcrumbData} />
      </div>
      <div className={classes.root}>
        {!isMobile && (
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, classes.drawerOpen)}
            classes={{
              paper: classNames(classes.paper, classes.drawerOpen),
            }}
          >
            <div>
              <div className={classes.toolbar}>
                <Typography component="span" aria-hidden="true">
                  My Account
                </Typography>
              </div>
              <List disablePadding>
                {links
                  .filter(item => !item.subroute)
                  .map(
                    item =>
                      item?.label !== 'default' && (
                        <ListItem
                          button
                          disabled={isDisabled(item.label)}
                          className={classes.link}
                          component={Link}
                          to={item.path}
                          key={item.id}
                          onClick={onClick(item.path)}
                          getProps={({ isCurrent }) => {
                            const isSubRoute = path?.includes(item.path)
                            return {
                              style:
                                isCurrent || isSubRoute
                                  ? { backgroundColor: alpha(theme.palette.primary.main, 0.1) }
                                  : {},
                              'data-active': isCurrent || isSubRoute ? 'true' : 'false',
                            }
                          }}
                        >
                          <ListItemIcon>
                            <item.icon />
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ),
                  )}
                <Divider />
                <ListItem button onClick={actions.logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log Out" />
                </ListItem>
              </List>
            </div>
          </Drawer>
        )}
        <main className={classes.content}>
          <MobileAppBar />
          <FramerRouter>
            <Redirect from="/" to="/account/profile" noThrow />
            {links.map(item => (
              <item.component key={`routes-${item.id}`} path={item?.path?.replace?.('/account', '')} />
            ))}
          </FramerRouter>
        </main>
      </div>
    </>
  )
}

export default AccountsDrawer
